import React from "react";
import Paragraph from "@narative/gatsby-theme-novela/src/components/Paragraph/Paragraph";
import Section from "@narative/gatsby-theme-novela/src/components/Section";
import styled from "@emotion/styled";
import mediaqueries from "@narative/gatsby-theme-novela/src/styles/media";
import { graphql, useStaticQuery } from "gatsby";
import Anchor from "@narative/gatsby-theme-novela/src/components/Anchor/Anchor";

const Text = styled.p`
  margin: 0 auto 30px;
  color: ${p => p.theme.colors.grey};
  line-height: 1.75;
  ${mediaqueries.tablet`
    padding: 0 26px;
    margin: 0 auto 25px;
  `}
`;

const siteQuery = graphql`
  {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { date: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            date
            series
          }
        }
      }
    }
  }
`;

export function PythonForFilm() {
    const results = useStaticQuery(siteQuery);
    const posts = results.allMdx.edges
        .map(edge => edge.node)
        .map(node => node.frontmatter)
        .filter(front => front.series === "python-for-film");
  return (
      <Section>


      <Paragraph>
        <div style={{
            backgroundColor: "rgba(128,128,128,0.1)",
            padding: "20px",
            borderRadius: "12px"
        }}>
          Posts in this Series:

            <ul style={{
                marginLeft: "3rem"
            }}>
                {posts.map((post, i) =>
                    <li><Anchor href={'/'+post.slug} style={{
                        fontSize: "12px"
                    }}>{post.title}</Anchor></li>
                )}
            </ul>

        </div>

      </Paragraph>
  </Section>
  );
}

export function PythonForFilmIntro() {
    return (
        <Section>
            <Paragraph>
                <Text style={{
                fontStyle: "italic",
                fontSize: "75%",
                color: "rgba(128,128,128,0.8)"
            }}>
                This post was originally part of my series on <span style={{
                fontWeight:"bold"
            }}>Python for Feature Film</span> on <a href="http://www.dgovil.com" style={{
                fontWeight:"bold"
            }}>my personal site</a>,
                but is being ported here with minor changes. The dates have been adjusted to match release dates for the
                projects.
                </Text>
            </Paragraph>
        </Section>
    )
}

export default PythonForFilm

